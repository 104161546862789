export const albums_data = {
    "Hochzeit": {
        "name": "Hochzeit",
        "photos": [
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x361_01_IMG_2197.jpg",
                "faces": {},
                "height": 4000,
                "name": "01_IMG_2197.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1156_01_IMG_2197.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x361_01_IMG_2197.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x578_01_IMG_2197.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x739_01_IMG_2197.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1156_01_IMG_2197.jpg 1600w"
                ],
                "width": 5536
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x333_02_IMG_2206.jpg",
                "faces": {},
                "height": 4000,
                "name": "02_IMG_2206.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1066_02_IMG_2206.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x333_02_IMG_2206.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x533_02_IMG_2206.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x682_02_IMG_2206.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1066_02_IMG_2206.jpg 1600w"
                ],
                "width": 6000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x333_03_IMG_2278.jpg",
                "faces": {},
                "height": 4000,
                "name": "03_IMG_2278.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1066_03_IMG_2278.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x333_03_IMG_2278.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x533_03_IMG_2278.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x682_03_IMG_2278.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1066_03_IMG_2278.jpg 1600w"
                ],
                "width": 6000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x333_04_IMG_2231.jpg",
                "faces": {},
                "height": 4000,
                "name": "04_IMG_2231.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1066_04_IMG_2231.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x333_04_IMG_2231.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x533_04_IMG_2231.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x682_04_IMG_2231.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1066_04_IMG_2231.jpg 1600w"
                ],
                "width": 6000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x750_05_IMG_2302.jpg",
                "faces": {},
                "height": 6000,
                "name": "05_IMG_2302.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x2400_05_IMG_2302.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x750_05_IMG_2302.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x1200_05_IMG_2302.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x1536_05_IMG_2302.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x2400_05_IMG_2302.jpg 1600w"
                ],
                "width": 4000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x333_06_IMG_2339.jpg",
                "faces": {},
                "height": 4000,
                "name": "06_IMG_2339.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1066_06_IMG_2339.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x333_06_IMG_2339.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x533_06_IMG_2339.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x682_06_IMG_2339.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1066_06_IMG_2339.jpg 1600w"
                ],
                "width": 6000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x692_07_Projekt_KimPatrick_14.jpg",
                "faces": {},
                "height": 2126,
                "name": "07_Projekt_KimPatrick_14.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1535x2126_07_Projekt_KimPatrick_14.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x692_07_Projekt_KimPatrick_14.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x1108_07_Projekt_KimPatrick_14.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x1418_07_Projekt_KimPatrick_14.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1535x2126_07_Projekt_KimPatrick_14.jpg 1535w"
                ],
                "width": 1535
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x333_08_IMG_2353.jpg",
                "faces": {},
                "height": 4000,
                "name": "08_IMG_2353.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1066_08_IMG_2353.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x333_08_IMG_2353.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x533_08_IMG_2353.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x682_08_IMG_2353.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1066_08_IMG_2353.jpg 1600w"
                ],
                "width": 6000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x692_09_Projekt_KimPatrick_32.jpg",
                "faces": {},
                "height": 2126,
                "name": "09_Projekt_KimPatrick_32.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1535x2126_09_Projekt_KimPatrick_32.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x692_09_Projekt_KimPatrick_32.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x1108_09_Projekt_KimPatrick_32.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x1418_09_Projekt_KimPatrick_32.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1535x2126_09_Projekt_KimPatrick_32.jpg 1535w"
                ],
                "width": 1535
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x500_10_Projekt_KimPatrick_43.jpg",
                "faces": {},
                "height": 3543,
                "name": "10_Projekt_KimPatrick_43.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1600_10_Projekt_KimPatrick_43.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x500_10_Projekt_KimPatrick_43.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x800_10_Projekt_KimPatrick_43.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x1024_10_Projekt_KimPatrick_43.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1600_10_Projekt_KimPatrick_43.jpg 1600w"
                ],
                "width": 3543
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x606_11_1_Projekt_KimPatrick_39.jpg",
                "faces": {},
                "height": 2863,
                "name": "11_1_Projekt_KimPatrick_39.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1939_11_1_Projekt_KimPatrick_39.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x606_11_1_Projekt_KimPatrick_39.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x969_11_1_Projekt_KimPatrick_39.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x1241_11_1_Projekt_KimPatrick_39.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1939_11_1_Projekt_KimPatrick_39.jpg 1600w"
                ],
                "width": 2362
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x666_11_2_IMG_20200905_215948.jpg",
                "faces": {},
                "height": 4032,
                "name": "11_2_IMG_20200905_215948.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x2133_11_2_IMG_20200905_215948.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x666_11_2_IMG_20200905_215948.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x1066_11_2_IMG_20200905_215948.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x1365_11_2_IMG_20200905_215948.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x2133_11_2_IMG_20200905_215948.jpg 1600w"
                ],
                "width": 3024
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x750_12_IMG_2498.jpg",
                "faces": {},
                "height": 6000,
                "name": "12_IMG_2498.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x2400_12_IMG_2498.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x750_12_IMG_2498.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x1200_12_IMG_2498.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x1536_12_IMG_2498.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x2400_12_IMG_2498.jpg 1600w"
                ],
                "width": 4000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x375_13_IMG_20200904_191735.jpg",
                "faces": {},
                "height": 2736,
                "name": "13_IMG_20200904_191735.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1200_13_IMG_20200904_191735.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x375_13_IMG_20200904_191735.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x600_13_IMG_20200904_191735.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x768_13_IMG_20200904_191735.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1200_13_IMG_20200904_191735.jpg 1600w"
                ],
                "width": 3648
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x361_14_1_Projekt_KimPatrick_59.jpg",
                "faces": {},
                "height": 1535,
                "name": "14_1_Projekt_KimPatrick_59.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1599x1155_14_1_Projekt_KimPatrick_59.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x361_14_1_Projekt_KimPatrick_59.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/799x577_14_1_Projekt_KimPatrick_59.jpg 799w",
                    "/static/_gallery/albums/Hochzeit/1024x739_14_1_Projekt_KimPatrick_59.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1599x1155_14_1_Projekt_KimPatrick_59.jpg 1599w"
                ],
                "width": 2126
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x333_14_2_IMG_2651.JPG",
                "faces": {},
                "height": 4000,
                "name": "14_2_IMG_2651.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1066_14_2_IMG_2651.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x333_14_2_IMG_2651.JPG 500w",
                    "/static/_gallery/albums/Hochzeit/800x533_14_2_IMG_2651.JPG 800w",
                    "/static/_gallery/albums/Hochzeit/1024x682_14_2_IMG_2651.JPG 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1066_14_2_IMG_2651.JPG 1600w"
                ],
                "width": 6000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x333_14_3_IMG_2637.JPG",
                "faces": {},
                "height": 4000,
                "name": "14_3_IMG_2637.JPG",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1066_14_3_IMG_2637.JPG",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x333_14_3_IMG_2637.JPG 500w",
                    "/static/_gallery/albums/Hochzeit/800x533_14_3_IMG_2637.JPG 800w",
                    "/static/_gallery/albums/Hochzeit/1024x682_14_3_IMG_2637.JPG 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1066_14_3_IMG_2637.JPG 1600w"
                ],
                "width": 6000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x375_15_IMG_20200904_200030.jpg",
                "faces": {},
                "height": 2736,
                "name": "15_IMG_20200904_200030.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1200_15_IMG_20200904_200030.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x375_15_IMG_20200904_200030.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x600_15_IMG_20200904_200030.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x768_15_IMG_20200904_200030.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1200_15_IMG_20200904_200030.jpg 1600w"
                ],
                "width": 3648
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x375_16_IMG_20200904_200100.jpg",
                "faces": {},
                "height": 2736,
                "name": "16_IMG_20200904_200100.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1200_16_IMG_20200904_200100.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x375_16_IMG_20200904_200100.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x600_16_IMG_20200904_200100.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x768_16_IMG_20200904_200100.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1200_16_IMG_20200904_200100.jpg 1600w"
                ],
                "width": 3648
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x375_17_IMG_20200904_233124.jpg",
                "faces": {},
                "height": 3000,
                "name": "17_IMG_20200904_233124.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x1200_17_IMG_20200904_233124.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x375_17_IMG_20200904_233124.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x600_17_IMG_20200904_233124.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x768_17_IMG_20200904_233124.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x1200_17_IMG_20200904_233124.jpg 1600w"
                ],
                "width": 4000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x750_18_IMG_2699.jpg",
                "faces": {},
                "height": 6000,
                "name": "18_IMG_2699.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x2400_18_IMG_2699.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x750_18_IMG_2699.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x1200_18_IMG_2699.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x1536_18_IMG_2699.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x2400_18_IMG_2699.jpg 1600w"
                ],
                "width": 4000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/500x750_19_IMG_2714.jpg",
                "faces": {},
                "height": 6000,
                "name": "19_IMG_2714.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1600x2400_19_IMG_2714.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/500x750_19_IMG_2714.jpg 500w",
                    "/static/_gallery/albums/Hochzeit/800x1200_19_IMG_2714.jpg 800w",
                    "/static/_gallery/albums/Hochzeit/1024x1536_19_IMG_2714.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1600x2400_19_IMG_2714.jpg 1600w"
                ],
                "width": 4000
            },
            {
                "_thumb": "/static/_gallery/albums/Hochzeit/499x1058_20_IMG_20200905_010249.jpg",
                "faces": {},
                "height": 4608,
                "name": "20_IMG_20200905_010249.jpg",
                "sizes": [
                    "(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"
                ],
                "src": "/static/_gallery/albums/Hochzeit/1599x3388_20_IMG_20200905_010249.jpg",
                "srcSet": [
                    "/static/_gallery/albums/Hochzeit/499x1058_20_IMG_20200905_010249.jpg 499w",
                    "/static/_gallery/albums/Hochzeit/799x1694_20_IMG_20200905_010249.jpg 799w",
                    "/static/_gallery/albums/Hochzeit/1024x2168_20_IMG_20200905_010249.jpg 1024w",
                    "/static/_gallery/albums/Hochzeit/1599x3388_20_IMG_20200905_010249.jpg 1599w"
                ],
                "width": 2176
            }
        ],
        "src": "/static/_gallery/albums/Hochzeit/500x361_01_IMG_2197.jpg"
    }
};